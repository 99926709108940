<template>


  <div class="tokens-manager">
    <v-row>
      <v-col class="d-flex justify-center">
        <div class="add-form mt-16">
          <div class="title">
            <v-icon>ico-rfid</v-icon>
            {{ $t('rfid.add-rfid') }}
          </div>


          <div class="btns">
            <v-btn color="success" @click="openEditRfidTag">
              <v-icon left>ico-plus</v-icon>
              {{ $t('add') }}
            </v-btn>
          </div>

        </div>
      </v-col>
      <v-col  class="d-flex justify-center">
        <div class="add-form mt-16">
          <div class="title">
            <v-row>
              <v-col class="d-flex justify-center">
                <v-icon>ico-rfid</v-icon>
                <v-icon class="mx-1">ico-rfid</v-icon>
                <v-icon>ico-rfid</v-icon>
              </v-col>
            </v-row>
            {{ $t('rfid.addMultiple.add') }}
          </div>


          <div class="btns">
            <v-btn color="success" @click="openMultipleRfidModal">
              <v-icon left>ico-plus</v-icon>
              {{ $t('add') }}
            </v-btn>
          </div>

        </div>
      </v-col>
    </v-row>

    <headline bg>
      {{ $tc('rfid.rfid', 2) }}
    </headline>



    <div class="main">

      <v-row class="align-center mb-2">
        <v-col>
          <v-menu
              v-if="selectedItems.length > 0"
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="btn-actions-menu"
              >
                {{ $tc('selected-items', selectedItems.length, {count: selectedItems.length})  }}
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <template v-for="(item, index) in actionOptions" >
                <v-divider :key="index" v-if="item.separator"></v-divider>
                <v-list-item
                    dense
                    v-else-if="item.disabled"
                    :key="index"
                    link
                    :ripple="false"
                    class="disabled"
                    @click.stop="item.disabledCallback ? item.disabledCallback() : () => {}"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                    :key="index"
                    dense
                    v-else
                    link
                    @click="item.callback ? item.callback() : () => {}"
                >
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </v-col>
        <v-col>
          <search-field v-model="search" clearable></search-field>
        </v-col>
      </v-row>

      <div class="table">
        <v-data-table
            class="rfid-list light"
            v-model="selectedItems"
            :headers="headers"
            :items="items"
            :server-items-length="total"
            :footer-props="{
              itemsPerPageOptions: [
                  10, 20, 50
              ]
            }"
            :options.sync="options"
            show-select
        >
          <template #item.uid="{item}">
            <v-row align="center">
              <v-col style="max-width: 30px;" class="align-self-start">
                <v-icon size="20">ico-rfid</v-icon>
              </v-col>
              <v-col>
                <div class="tag">
                  <router-link v-if="isDeveloper" :to="{name:'tokens.detail', params: {id: item.id}}">{{ item.uid }}</router-link>
                  <template v-else>{{ item.uid }}</template>
                </div>
                <div class="ref">{{ item.physicalReference }}</div>
              </v-col>
            </v-row>

          </template>

          <template #item.name="{item}">
            <div class="">
              {{ item.name }}
            </div>
          </template>

          <template #item.customer.lastname="{item}">
            <router-link v-if="item.customer" :to="{name: 'customer.detail', params: {id: item.customer.id}}">
              <template v-if="item.customer.firstname || item.customer.lastname">
                {{ item.customer.firstname }} {{ item.customer.lastname }}
              </template>
              <template v-else>
                {{ item.customer.evcoId }}
              </template>
            </router-link>
            <div v-else>
              -
            </div>
          </template>

          <template #item.member.customer.lastname="{item}">
            <router-link v-if="item.member?.customer?.lastname" :to="{name: 'member', params: {memberId: item.member.id}}">
              {{ item.member.customer.firstname }} {{ item.member.customer.lastname }}
            </router-link>
            <div v-else>
              -
            </div>
          </template>

          <template #item.organization.name="{item}">
            <router-link class="organization" v-if="item.organization?.name" :to="{name: 'organizations.detail', params: {id: item.organization.id}}">
              {{ item.organization.name }}
            </router-link>
            <div v-else>
              -
            </div>
          </template>

          <template #item.enabled="{value}">
            <v-icon color="green" v-if="value">
              mdi-check
            </v-icon>
            <v-icon color="red" v-else>
              mdi-close
            </v-icon>
          </template>

          <template #item.corporate="{value}">
            <v-icon color="green" v-if="value">
              mdi-check
            </v-icon>
          </template>

          <template #item.private="{value}">
            <v-icon color="green" v-if="value">
              mdi-check
            </v-icon>
          </template>

          <template #item.roaming="{value}">
            <v-icon color="green" v-if="value">
              mdi-check
            </v-icon>
          </template>

          <template #item.lastUsageAt="{value}">
            <div class="text-no-wrap">
              {{ value|formatDate }}
            </div>
          </template>


          <template #item.options="{item}">
            <div class="options">
              <v-btn icon rounded @click="openEditRfidTag(item)" class="mr-2">
                <v-icon>ico-edit</v-icon>
              </v-btn>
              <v-btn icon rounded @click="deleteRfidCard(item.id)">
                <v-icon>ico-trash</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>

      </div>
    </div>






    <rfid-form-modal ref="rfidFormModal" @change="onRfidChanged" :customer-id="customerId" :organization-id="organizationId" />

    <v-dialog v-model="addMultipleRfidsModal" max-width="600">
      <v-form @submit.prevent="addMultipleRfidCards">
        <v-card>
          <v-card-title>
            {{ $t('rfid.addMultiple.add') }}
          </v-card-title>
          <v-card-text>
            <v-row v-if="!addMultipleRfidsResult">
              <v-col>
                <v-textarea
                    :label="$t('rfid.rfid-card-no')"
                    v-model="multipleRfids"
                    outlined
                    rows="10"
                    :placeholder="$t('rfid.addMultiple.rfids-placeholder')"
                    :disabled="addMultipleRfidCardsLoading"
                    :loading="addMultipleRfidCardsLoading"
                />
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col>

                <v-alert type="success">{{ $t('rfid.addMultiple.finished')}}</v-alert>


                <div>
                  <span class="green--text">{{ $t('rfid.addMultiple.added') }} : <b>{{ addMultipleRfidsResult.added.length }}</b></span>
                  <div style="font-size: 11px;" v-if="addMultipleRfidsResult.added.length > 0">{{ addMultipleRfidsResult.added.join(', ') }}</div>
                </div>

                <v-divider class="my-4"></v-divider>

                <div>
                  <span class="orange--text">{{ $t('rfid.addMultiple.duplicated') }} : <b>{{ addMultipleRfidsResult.duplicated.length }}</b></span>
                  <div style="font-size: 11px;" v-if="addMultipleRfidsResult.duplicated.length > 0">{{ addMultipleRfidsResult.duplicated.join(', ') }}</div>
                </div>

                <v-divider class="my-4"></v-divider>

                <div>
                  <span  class="red--text">{{ $t('rfid.addMultiple.invalid') }} : <b>{{ addMultipleRfidsResult.invalid.length }}</b></span>
                  <div style="font-size: 11px;" v-if="addMultipleRfidsResult.invalid.length > 0"> {{ addMultipleRfidsResult.invalid.join(', ') }}</div>
                </div>

              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-row class="ma-0" v-if="!addMultipleRfidsResult">
              <v-col class="text-right">
                <v-btn text type="button" class="mr-3" @click="addMultipleRfidsModal = false" :disabled="addMultipleRfidCardsLoading">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn color="primary" type="submit" :disabled="addMultipleRfidCardsLoading">
                  {{ $t('add') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row  v-else>
              <v-col class="text-right">
                <v-btn text type="button" class="mr-3" @click="addMultipleRfidsModal = false" :disabled="addMultipleRfidCardsLoading">
                  {{ $t('close') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>

<script>
import RfidFormModal from "@modals/RfidFormModal.vue";
import RulesMixin from "@mixins/RulesMixin";
import TokenRepository from "@repository/TokenRepository";
import tokenRepository from "@repository/TokenRepository";

export default {

  components: {
    RfidFormModal
  },

  data() {
    return {
      search: null,
      addMultipleRfidsModal: false,
      tokens: [],
      options: null,
      total: 0,
      rfidForm: {
        uid: null,
        name: null,
        physicalReference: null,
        enabled: null,
      },
      addRfidTagLoading: false,
      deleteRfidTagLoading: false,
      multipleRfids: null,
      addMultipleRfidCardsLoading: false,
      addMultipleRfidsResult: null,
      selectedItems: [],
    }
  },

  props: {
    customerId: {type: String, default: null},
    organizationId: {type: String, default: null},
  },

  mixins: [
    RulesMixin,
  ],

  mounted() {

  },

  watch: {
    options() {
      this.loadData()
    },

    search() {
      this.loadData()
    },
  },

  computed: {


    actionOptions() {

      return [
        {
          text: this.$t('rfid.action-multiple-enable'),
          value: 'enable',
          callback: () => {
            this.showLoading(true)
            tokenRepository.actionForMultipleRfid('enable', this.selectedItems.map(i => i.id)).then(() => {
              this.selectedItems = []
              this.loadData()
            }).catch(err => {
              this.showLoading(false)
              this.$dialog.notify.error(err.response?.data?.message || err.message)
            })
          }
        },
        {
          text: this.$t('rfid.action-multiple-disable'),
          value: 'disable',
          callback: () => {
            this.showLoading(true)
            tokenRepository.actionForMultipleRfid('disable', this.selectedItems.map(i => i.id)).then(() => {
              this.selectedItems = []
              this.loadData()
            }).catch(err => {
              this.showLoading(false)
              this.$dialog.notify.error(err.response?.data?.message || err.message)
            })
          }
        },
      ]
    },

    headers() {
      const headers =  [
        {text: this.$t('rfid.rfid-card-no'), value: "uid", align: 'left'},
        {text: this.$t('rfid.rfid-card-name'), value: "name", align: 'left'},
        {text: this.$t('rfid.rfid-corporate'), value: "corporate", align: 'left'},
        {text: this.$t('rfid.rfid-added-by-customer'), value: "private", align: 'left'},
        {text: this.$t('last-usage'), value: "lastUsageAt", align: 'left'},
        {text: this.$t('rfid.rfid-card-enabled'), value: "enabled", align: 'left'},
        {text: "", value: "options", sortable: false},
      ]

      if(!this.organizationId && !this.customerId) {
        headers.splice(1, 0, ...[
          {text: this.$tc('customer.customer'), value: "customer.lastname", align: 'left'},
          {text: this.$t('organization.organization'), value: "organization.name", align: 'left'},
        ])
      } else if(this.organizationId && !this.customerId) {
        headers.splice(1, 0, ...[
          {text: this.$tc('member.member', 1), value: "member.customer.lastname", align: 'left'},
        ])
      } else if(!this.organizationId && this.customerId) {
        headers.splice(1, 0, ...[
          {text: this.$t('organization.organization'), value: "organization.name", align: 'left'},
        ])
      }
      return headers
    },


    items() {
      return this.tokens || []
    },

  },

  methods: {

    loadData() {
      return new Promise((resolve) => {
        let searchText = this.search;
        let offset = this.options.itemsPerPage * this.options.page - this.options.itemsPerPage;
        let limit = this.options.itemsPerPage;
        let sortBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'createdAt'
        let sortOrder = this.options.sortDesc.length > 0 && this.options.sortDesc[0] ? 'desc' : 'asc'


        this.showLoading(true)

        const data = {
          searchText,
          offset,
          limit,
          sortBy,
          sortOrder,
        }

        if(this.customerId) {
          data.customerId = this.customerId
        }

        if(this.organizationId) {
          data.organizationId = this.organizationId
        }

        TokenRepository.search(data).then(data => {
          this.tokens = data.items
          this.total = data.total
          this.showLoading(false)
          resolve()
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
          this.showLoading(false)
        })
      })
    },

    openMultipleRfidModal() {
      this.multipleRfids = null;
      this.addMultipleRfidsResult = null;
      this.addMultipleRfidsModal = true;
    },

    addMultipleRfidCards() {
      if (this.multipleRfids?.trim()) {
        this.addMultipleRfidCardsLoading = true;

        const data = {
          uids: this.multipleRfids.split(/[\n;, ]+/).filter(i => i.trim()).map(i => i.trim()),
        }

        if(this.customerId) {
          data.customerId = this.customerId
        }

        if(this.organizationId) {
          data.organizationId = this.organizationId
        }

        TokenRepository.createMultiple(data).then((response) => {
          this.addMultipleRfidsResult = response
          this.loadData().then(() => {
            this.addMultipleRfidCardsLoading = false;
          })
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
          this.addMultipleRfidCardsLoading = false;
        })
      }
    },

    onRfidChanged() {
      this.loadData()
    },

    openEditRfidTag(item) {
      this.$refs.rfidFormModal.open({rfidId: item?.id})
    },


    deleteRfidCard(id) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          this.deleteRfidTagLoading = true
          TokenRepository.delete(id).then(() => {
            this.loadData().then(() => {
              this.deleteRfidTagLoading = false
              this.showLoading(false)
            })
          }).catch(err => {
            this.$dialog.notify.error(err.response?.data?.message || err.message)
            this.showLoading(false)
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.tokens-manager {
  & > .main {
    .table {
      tr {
        &:first-child {
          th {
            &:first-child {
              i {
                padding-left: 10px;
              }

              .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
                background-color: transparent !important;
              }
            }
          }
        }
        td {
          a {
            font-size: 14px;
          }
        }

      }



      .tag {
        color: black;

        .v-icon {
          margin-right: 40px;
        }
      }

      .name {
        color: var(--v-primary-base);
      }

      .ref {
        color: #3b3b3b;
        font-size: 14px;
        font-weight: 400;
      }

      .options {
        .v-btn--icon {
          border: 1px solid #758093;
          width: 30px;
          height: 30px;

          i {
            font-size: 16px;
            color: #758093;
          }
        }
      }
    }


  }

  .add-form {
    position: relative;
    margin: 30px 0 60px 30px;
    background-color: #fff;
    border: 1px solid var(--v-primary-base);
    padding: 20px 20px 50px 20px;
    border-radius: 15px;
    color: #758093;
    width: 400px;
    text-transform: uppercase;

    .title {
      text-align: center;
      color: var(--v-primary-base);
      font-weight: bold;

      i {
        color: var(--v-primary-base);
        display: block;
      }
    }

    .v-text-field {
      box-shadow: 0 10px 30px #00000029;

      .v-input__slot {
        border-color: #75809340;
      }
    }

    .btns {
      text-align: center;
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: -30px;
      font-size: 22px;
    }
  }

}

.v-list {
  .v-list-item {
    &.disabled {
      .v-list-item__title {
        cursor: not-allowed;
        color: rgba(0, 0, 0, 0.38) !important;
      }
    }
  }
}

</style>
